<template>    
   <section class="contact" data-scroll-index="6">
       <div class="container-fluid">
           <div class="row">
                <div class="col-lg-4 col-md-6 contact-info half-padding bg-img" data-overlay-dark="5" v-lazy:background-image="`${publicPath}images/bg4.jpg`">
                    <div class="ontop text-white">
                        <div class="mb-10">
                          <h5 class="text-white">Get In Touch</h5>
                        </div>                        
                        <div class="item">
                            <span class="icon"><i class="icofont-phone"></i></span>
                            <div class="cont">
                                <h6>Phone</h6>
                                <p>+44(0) 7888721112</p>
                            </div>
                        </div>
                        <div class="item">
                            <span class="icon"><i class="icofont-send-mail"></i></span>
                            <div class="cont">
                                <h6>Email</h6>
                                <p><router-link :to="{hash:'mailto:info@nidify.co.uk'}" title="Email Us"> info@nidify.co.uk </router-link></p>
                            </div>
                        </div>
                        <div class="item">
                            <span class="icon"><i class="icofont-map"></i></span>
                            <div class="cont">
                                <h6>Address</h6>
                                <p>Harrow, London, United Kingdom</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 purple-color-bg">
                    <form class="form half-padding" name="contact-form" id="contact-form">
                        <div class="mb-30">
                          <h5 class="text-white">Send Enquiry</h5>
                        </div>                        
                        <div class="controls">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <span class="icon-form"><i class="icofont-ui-user"></i></span>
                                        <input id="form_name" type="text" name="name" placeholder="Name" required="required">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <span class="icon-form"><i class="icofont-envelope"></i></span>
                                        <input id="form_email" type="email" name="email" placeholder="Email" required="required">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <span class="icon-form"><i class="icofont-edit"></i></span>
                                        <input id="form_subject" type="text" name="subject" placeholder="Subject">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <span class="icon-form"><i class="icofont-comment"></i></span>
                                        <textarea id="form_message" name="message" placeholder="Message" rows="4" required="required"></textarea>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <button type="submit" class="btn">Send Message</button>
                                </div>
                            </div>                             
                        </div>
                    </form>
                </div>
                <div class="col-lg-4 map">
		            <div id="ieatmaps"></div>
                </div>
           </div>
       </div>
    </section>
</template>    
<script>
export default {
    data () {
        return {
            publicPath: process.env.BASE_URL
        }
    }    
}
</script>
